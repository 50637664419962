import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "./Table.scss";
import paginationLeft from "src/assets/images/paginationLeft.svg";
import paginationRight from "src/assets/images/paginationRight.svg";
import sortAsc from "src/assets/images/sort-up.svg";
import sortDesc from "src/assets/images/sort-down.svg";
import omit from 'lodash/omit'

type TableProps = {
  searchBy?: string;
  columns: Array<any>;
  data: Array<any>;
  theadStyle?: any;
  paginationOff?: boolean;
};

const Table: React.FC<TableProps> = (props) => {
  const memoizedColumns = useMemo(() => props.columns, [props.columns]);
  const memoizedData = useMemo(() => props.data, [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    page,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: props.paginationOff ? {} : { pageIndex: 0, pageSize: 25 },
    },
    useSortBy,
    usePagination
  );

  const ShowTableData = () => (
    <>
      <div className="pure-table">
        {/* <div
          className={
            props.searchBy ? "mb-1 ml-1 visible" : "mb-1 ml-1 invisible"
          }
        >
          Found <b>{memoizedData.length}</b> results for "{props.searchBy}"
        </div> */}

        <div>
          <table {...getTableProps()} className="table">
            <colgroup>
              {props.columns.map((column, index) => {
                return (
                  <col
                    key={index}
                    className={`table-col-${column.percentWidth}`}
                  />
                );
              })}
            </colgroup>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  style={props.theadStyle}
                  {...omit(headerGroup.getHeaderGroupProps())}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src={sortDesc}
                              className="ml-1"
                              alt="sortDesc"
                            />
                          ) : (
                            <img src={sortAsc} className="ml-1" alt="sortAsc" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          <span title={cell.value}>{cell.render("Cell")}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {!props.paginationOff && (
          <div className="pagination-container mt-2">
            <div className="d-flex justify-content-end align-items-center mb-1">
              <div>
                Viewing <b>{pageIndex * pageSize + 1}</b> -{" "}
                <b>{pageIndex * pageSize + page.length}</b> of{" "}
                <b>{memoizedData && memoizedData.length}</b>
              </div>
              <div>
                <img
                  src={paginationLeft}
                  onClick={() => previousPage()}
                  className="previous-page ml-1"
                  alt="leftArrow"
                  height="30"
                  width="30"
                />
                <img
                  src={paginationRight}
                  onClick={() => nextPage()}
                  className="next-page"
                  alt="rightArrow"
                  height="30"
                  width="30"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="pure-table">
      {memoizedData.length ? <ShowTableData /> : null}
    </div>
  );
};

export default Table;
