import React from "react";
import "./Clock.scss";
import styled, { keyframes, css } from "styled-components";
import moment from "moment";

type ClockProps = {
  time: {
    hours: string;
    minutes: string;
    seconds: string;
  };
  shutdownAtTime: Date;
  createdOn: Date;
};

const Line = styled.line`
  ${(props) =>
    css`
      animation: ${props.rotateHand} ${props.timeRemainingSeconds}s linear;
    `}
`;

const Clock: React.FC<ClockProps> = ({ time, shutdownAtTime, createdOn }) => {
  const initialTime = React.useMemo(
    () =>
      moment(moment(shutdownAtTime.toString()).local()).diff(
        moment(createdOn.toString()),
        "seconds"
      ),
    [shutdownAtTime, createdOn]
  );

  const timeRemainingSeconds =
    +time?.hours * 60 * 60 + +time?.minutes * 60 + +time?.seconds;

  const timeSpent = initialTime - timeRemainingSeconds;

  const rotateHand = keyframes`
      from {
        transform: rotate(${(360 * timeSpent) / initialTime}deg);
      }
      to {
        transform: rotate(360deg);
      }
    `;

  const extendedTimeEffect =
    Number(time?.hours) === 0 && Number(time?.minutes) <= 30
      ? "var(--color-primary)"
      : "var(--color-white)";

  return (
    <div className="clock visible">
      <svg
        viewBox="0 0 40 40"
        fill="transparent"
        stroke="white"
        strokeLinecap="round"
      >
        <circle cx="20" cy="20" r="19" stroke="transparent" />
        <g className="marks">
          {Array.from(Array(45).keys()).map(() => (
            <line x1="15" y1="0" x2="16" y2="0" stroke={extendedTimeEffect} />
          ))}
          <text
            x="-10"
            y="3"
            fill={extendedTimeEffect}
            stroke={extendedTimeEffect}
            strokeWidth="0.6"
            className="time-text"
          >
            {!!time?.hours &&
              !!time.minutes &&
              `${+time.hours >= 0 ? time.hours : 0}:${
                +time.minutes >= 0 ? time.minutes : 0
              }`}
          </text>
          <Line
            x1="3"
            y1="0"
            x2="18"
            y2="0"
            className="time"
            stroke={extendedTimeEffect}
            rotateHand={rotateHand}
            timeRemainingSeconds={timeRemainingSeconds}
          />
        </g>
      </svg>
    </div>
  );
};

export default Clock;
