import { combineReducers } from "redux";
import usersReducer from "./users/reducer";
import groupsReducer from "./groups/reducer";
import companiesReducer from "./companies/reducer";
import UIReducer from "./ui/reducer";
import vouchersReducer from "./vouchers/reducer";
import labsReducer from "./labs/reducer";
import userReducer from "./user/reducer";
import storylineReducer from "./storyline/reducer";
import storylineListReducer from "./storylineList/reducer";
import presentationReducer from "./presentations/reducer";
import sidebarReducer from "./sidebar/reducer";
import notificationReducer from "./notifications/reducer";
import userNotificationsReducer from "./userNotifications/reducer"

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  companies: companiesReducer,
  groups: groupsReducer,
  ui: UIReducer,
  vouchers: vouchersReducer,
  labs: labsReducer,
  storyline: storylineReducer,
  storylineList: storylineListReducer,
  presentationList: presentationReducer,
  sidebar: sidebarReducer,
  notifications: notificationReducer,
  userNotifications: userNotificationsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
