import React, { useState } from "react";
import "./ChangePassword.scss";
import FormLayout from "src/components/shared/FormLayout";
import { Form, Button, FormControl, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { LABS_ROUTE } from "src/constants/appRoutes";
import { useHistory } from "react-router-dom";
import { changePassword } from "src/api/users";
import lockIcon from "src/assets/images/lockIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import invisibleIcon from "src/assets/images/invisibleIcon.svg";
import { formatError, isEmpty } from "src/utils/common";
import {PasswordRegex} from "src/constants/regex";

type ChangePasswordProps = {};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const history = useHistory();

  const changePage = () => history.push(`${LABS_ROUTE}`);

  const onSubmitHandle = async (details, actions) => {
    actions.setSubmitting(true);
    const payload = {
      new_password: details?.newPassword?.trim(),
      old_password: details?.currentPassword?.trim(),
    };
    const resp = await changePassword(payload);
    actions.setSubmitting(false);
    if (!isEmpty(resp) && !resp.hasError) {
      setTimeout(() => {
        changePage();
      }, 2000);
    } else if (!isEmpty(resp) && resp.hasError) {
      actions.setStatus({ formSubmitMessage: formatError(resp) });
    }
  };

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("This field is required"),
    newPassword: Yup.string()
      .required("This field is required")
      .min(8, "Password is too short, it must contain at least 8 characters")
      .max(100, "Your password cannot exceed a maximum of 100 characters")
      .matches(PasswordRegex, {
        message:
          "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol",
      }),
    confirmPassword: Yup.string()
      .required("This field is required")
      .test(
        'match-password',
        'Passwords do not match',
        function (this, value) {
          return !!value?.trim() && value.trim() === this.parent.newPassword.trim()
        }
      )
      // .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  return (
    <div className="lab-card d-flex flex-column flex-shrink-1 flex-grow-1">
      <h5 className="ml-1 mb-3 mt-4">
        <b>Change Password</b>
      </h5>

      <FormLayout>
        <div className="d-flex flex-row pb-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandle}
          >
            {(formik) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="pt-4 pl-5 change-password-form"
              >
                <Form.Group className="d-flex justify-content-sm-between align-items-baseline">
                  <Form.Label className="change-password-form-label">
                    Current Password
                    <RequiredFieldSymbol />
                  </Form.Label>
                  <div className="w-100 ml-3">
                    <InputGroup className="cp-form-input">
                      <InputGroup.Prepend>
                        <InputGroup.Text className="input-icon-background">
                          <img src={lockIcon} alt="lockIcon" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        id="currentPassword"
                        placeholder="Old Password"
                        className="cp-form-input-box border-none"
                        aria-describedby="basic-addon1"
                        type={showCurrentPassword ? "text" : "password"}
                        value={formik.values.currentPassword}
                        onChange={(e) => {
                          formik.setStatus({ formSubmitMessage: "" });
                          formik.handleChange(e);
                        }}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="input-icon-background cursor-pointer">
                          <img
                            onClick={() =>
                              !!formik.values.currentPassword &&
                              setShowCurrentPassword(!showCurrentPassword)
                            }
                            src={
                              showCurrentPassword ? visibleIcon : invisibleIcon
                            }
                            alt="invisibleIcon"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {formik.touched.currentPassword &&
                      formik.errors.currentPassword && (
                        <InputErrorMessage
                          className={"error-message"}
                          errorMessage={formik.errors.currentPassword || ""}
                        />
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="d-flex justify-content-sm-between align-items-baseline">
                  <Form.Label className="change-password-form-label">
                    New Password
                    <RequiredFieldSymbol />
                  </Form.Label>

                  <div className="w-100 ml-3">
                    <InputGroup className="cp-form-input">
                      <InputGroup.Prepend>
                        <InputGroup.Text className="input-icon-background">
                          <img src={lockIcon} alt="lockIcon" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        id="newPassword"
                        placeholder="New Password"
                        className="cp-form-input-box border-none"
                        aria-describedby="basic-addon1"
                        type={showNewPassword ? "text" : "password"}
                        value={formik.values.newPassword}
                        onChange={(e) => {
                          formik.setStatus({ formSubmitMessage: "" });
                          formik.handleChange(e);
                        }}
                        // {...formik.getFieldProps("newPassword")}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="input-icon-background cursor-pointer">
                          <img
                            onClick={() => {
                              !!formik.values.newPassword &&
                                setShowNewPassword(!showNewPassword);
                            }}
                            src={showNewPassword ? visibleIcon : invisibleIcon}
                            alt="invisibleIcon"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {formik.touched.newPassword &&
                      formik.errors.newPassword && (
                        <InputErrorMessage
                          className={"error-message"}
                          errorMessage={formik.errors.newPassword || ""}
                        />
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="d-flex justify-content-sm-between align-items-baseline">
                  <Form.Label className="change-password-form-label">
                    Confirm New Password
                    <RequiredFieldSymbol />
                  </Form.Label>
                  <div className="w-100 ml-3">
                    <InputGroup className="cp-form-input">
                      <InputGroup.Prepend>
                        <InputGroup.Text className="input-icon-background">
                          <img src={lockIcon} alt="lockIcon" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        id="confirmPassword"
                        placeholder="Confirm New Password"
                        className="cp-form-input-box border-none"
                        aria-describedby="basic-addon1"
                        type={showConfirmNewPassword ? "text" : "password"}
                        value={formik.values.confirmPassword}
                        onChange={(e) => {
                          formik.setStatus({ formSubmitMessage: "" });
                          formik.handleChange(e);
                        }}
                        // {...formik.getFieldProps("confirmPassword")}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="input-icon-background cursor-pointer">
                          <img
                            onClick={() => {
                              !!formik.values.confirmPassword &&
                                setShowConfirmNewPassword(
                                  !showConfirmNewPassword
                                );
                            }}
                            src={
                              showConfirmNewPassword
                                ? visibleIcon
                                : invisibleIcon
                            }
                            alt="invisibleIcon"
                          />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <InputErrorMessage
                          className={"error-message"}
                          errorMessage={formik.errors.confirmPassword || ""}
                        />
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-0 d-flex justify-content-sm-between align-items-baseline">
                  <Form.Label className="change-password-form-label"></Form.Label>
                  <div className="d-flex error-message w-100 p-2 ml-2">
                    {!!formik.status && formik.status.formSubmitMessage}
                  </div>
                </Form.Group>
                <Form.Group className="d-flex justify-content-sm-between align-items-baseline">
                  <Form.Label className="change-password-form-label"></Form.Label>
                  <div className="d-flex change-password-form-input p-2 ml-2">
                    <Button
                      type="button"
                      size="sm"
                      variant="outline-secondary"
                      className="footer-btn lab-cancel color-primary border-primary"
                      onClick={changePage}
                      disabled={formik.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary"
                      className="ml-2 footer-btn"
                      disabled={formik.isSubmitting}
                      onClick={(e) => formik.handleSubmit()}
                    >
                      Confirm
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      </FormLayout>
    </div>
  );
};
export default ChangePassword;
