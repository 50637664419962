import React, { useState } from "react";
import PureModal from "src/components/shared/PureModal";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Tab, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { postCustomerInfo } from "src/api/lab";
import { launchConsole } from "src/helpers/labs";
import { fetchLaunchParams } from "src/api/lab";
import { TextInput, RadioInput } from "src/components/ui/FormInputs";
import "./LaunchLab.scss";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { PRESENTATION_ROUTE, CONSOLE_ROUTE } from "src/constants/appRoutes";
import { toCamelCaseKeys } from "src/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "src/state/sidebar/actions";
import SideBar from "src/components/SideBar";
import { RootState } from "src/state/rootReducer";
import { isEmpty } from "src/utils/common";

Yup.addMethod(Yup.string, "customRequired", function (
  this,
  errorMsg,
  predicate
) {
  return this.test("customRequiredTest", errorMsg, function (value) {
    return (!!value && predicate) || !predicate;
  });
});

type LaunchLabProps = {
  lab: {
    name: string;
    id: string;
    customerInfoRequired: boolean;
    isActive?: boolean;
  };
  children?: (param) => void;
  btnLabel?: string;
};

const LaunchLab: React.FC<LaunchLabProps> = ({ lab, children, btnLabel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [usageTab, setUsageTab] = useState<string>("sales");
  const toggleUsageTab = (key) => setUsageTab(key);
  const [labLaunchModal, setLabLaunchModal] = useState<boolean>(false);
  const toggleLabLaunch = () => setLabLaunchModal(!labLaunchModal);
  const sidebar = useSelector((state: RootState) => state.sidebar);

  const validationSchema = Yup.object({
    name: Yup.string().customRequired("Name is required", usageTab === "sales"),
    company: Yup.string().customRequired(
      "Company is required",
      usageTab === "sales"
    ),
    usage: Yup.string().test(
      "customUsageRequired",
      "Usage Type is required",
      (value) =>
        (usageTab === "sales" && !value) || (usageTab !== "sales" && !!value)
    ),
    other: Yup.string().when("usage", {
      is: "other",
      then: Yup.string().required("Please explain"),
    }),
    email: Yup.string().email("Invalid email address"),
    reference: Yup.string(),
  });

  const initialValues = {
    instance: lab.name || "",
    name: "",
    usage: "",
    opportunityID: "",
    company: "",
    email: "",
    reference: "",
    other: "",
  };

  const getLaunchParams = async (labId, customerId?) => {
    const payload = customerId
      ? { lab: labId, customer_info: customerId }
      : { lab: labId };

    let response = await fetchLaunchParams(payload).catch((e) =>
      console.warn(e)
    );

    response = toCamelCaseKeys(response);
    if (response && !isEmpty(response)) {
      sidebar.sidebarOpen && dispatch(setSidebar());
      launchConsole(PRESENTATION_ROUTE, history, response);
    }
  };

  const getPayload = (values) => ({
    name: values.instance,
    lab: lab.id,
    reference: values.reference,
    usage_type: usageTab.toUpperCase(),
    usage:
      usageTab === "sales"
        ? {
            opportunity_id: values.opportunityID,
            company: values.company,
            name: values.name,
            email: values.email,
          }
        : {
            type: values.other || values.usage,
          },
  });

  const submitHandle = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (
      (values.company && values.name) ||
      values.other ||
      (values.usage && values.usage !== "other")
    ) {
      const payload = getPayload(values);
      let data = await postCustomerInfo(payload);
      data.id && data.lab && (await getLaunchParams(lab.id, data.id));
      toggleLabLaunch();
      resetForm();
      setSubmitting(false);
    }
  };

  const prepareLabLaunch = () => {
    if (lab.isActive) {
      sidebar.sidebarOpen && dispatch(setSidebar());
      launchConsole(CONSOLE_ROUTE, history, lab);
    } else {
      if (!!lab.customerInfoRequired) {
        toggleLabLaunch();
      } else {
        getLaunchParams(lab.id);
      }
    }
  };

  return (
    <>
      {children ? (
        children(prepareLabLaunch)
      ) : (
        <button className="card-footer-primary" onClick={prepareLabLaunch}>
          <span>{btnLabel}</span>
        </button>
      )}
      {labLaunchModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandle}
        >
          {(formik) => (
            <PureModal
              title="New Lab Instance"
              closeModal={toggleLabLaunch}
              showModal={labLaunchModal}
              primaryBtnLabel="Ok"
              primaryBtnAction={formik.handleSubmit}
              secondaryBtnLabel="Cancel"
              secondaryBtnAction={toggleLabLaunch}
              className={`lab-launch-modal ${
                (pathname.includes("/user/console") ||
                  pathname.includes("/user/presentation")) &&
                "highest-ZIndex"
              }`}
            >
              <Form>
                <TextInput label="Instance name" name="instance" required />
                <Form.Label>
                  Usage Type
                  <RequiredFieldSymbol />
                </Form.Label>
                <div className="usage-tabs">
                  <Tabs
                    defaultActiveKey={usageTab}
                    id="usageType"
                    className="usage-type mb-3"
                    onSelect={toggleUsageTab}
                  >
                    <Tab eventKey="sales" title="Sales Opportunity">
                      <TextInput
                        label="Opportunity ID"
                        name="opportunityID"
                        placeholder="Enter Opportunity ID"
                      />
                      <TextInput
                        label="Company"
                        name="company"
                        placeholder="Enter Company"
                        required
                      />
                      <TextInput
                        label="Name"
                        name="name"
                        placeholder="Enter Name"
                        required
                      />
                      <TextInput
                        label="Email"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </Tab>
                    <Tab eventKey="others" title="Others">
                      <RadioInput
                        name="usage"
                        className="usage-type-options"
                        options={[
                          {
                            label: "Personal Training",
                            id: "Personal Training",
                          },
                          {
                            label: "Trade show / event",
                            id: "Trade show / event",
                          },
                          {
                            label: "Lab QA / Testing",
                            id: "Lab QA / Testing",
                          },
                          {
                            label: "Other - Please Explain",
                            id: "other",
                            component: () => (
                              <TextInput
                                name="other"
                                placeholder="Enter Text"
                                required
                                textarea
                                rows="3"
                              />
                            ),
                          },
                        ]}
                      />
                    </Tab>
                  </Tabs>
                </div>
                <TextInput
                  label="Reference"
                  name="reference"
                  placeholder="Optional Text"
                  textarea
                  rows="3"
                />
              </Form>
            </PureModal>
          )}
        </Formik>
      )}
    </>
  );
};

export default LaunchLab;
