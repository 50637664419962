import React, { useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { TextInput, CheckInput } from "src/components/ui/FormInputs";
import { Button, InputGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { setLocalStorageItem } from "src/utils/common";
import { voucherSignup } from "src/api/vouchers";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { PasswordRegex } from "src/constants/regex";
import { USER_VOUCHER_ROUTE } from "src/constants/appRoutes";
import authLogo from "src/assets/images/authLogo.svg";
import invisibleIcon from "src/assets/images/invisibleIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import lockIcon from "src/assets/images/lockIcon.svg";
import codeIcon from "src/assets/images/voucher-code.svg";
import userIcon from "src/assets/images/userIcon.svg";
import emailIcon from "src/assets/images/resendPassword.svg";
import auth from "src/assets/images/auth.png";
import "./LabVoucher.scss";
import TermsAndPrivacySection from "src/components/TermsAndPrivacySection";
import { PRIVACY_URL, TNC_URL } from "src/constants/common";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  fname: Yup.string().required("First name is required"),
  sname: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short, it must contain at least 8 characters")
    .max(100, "Your password cannot exceed a maximum of 100 characters")
    .matches(PasswordRegex, {
      message:
        "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol",
    }),
  reenteredPassword: Yup.string()
    .required("Password is required")
    .test(
      'match-password',
      'Passwords do not match',
      function (this, value) {
        return !!value?.trim() && value.trim() === this.parent.password.trim()
      }
    ),
  acceptedTerms: Yup.boolean()
    .required()
    .oneOf([true], "You must accept the terms and conditions."),
});

const VoucherRegister = ({ launchLab, setVoucherError }) => {
  const { code } = useParams();
  const [loginError, setLoginError] = useState<any>("");
  const resetLoginError = () => setLoginError("");
  let [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  let [reEnteredpassVisible, toggleReEnteredPassVisibility] = useState<Boolean>(
    false
  );

  const changePasswordVisibility = (e) =>
    togglePasswordVisibility(!passwordVisible);

  const changeReEnteredPassVisibility = (e) =>
    toggleReEnteredPassVisibility(!reEnteredpassVisible);

  const onSubmitHandle = async (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    setSubmitting(true);
    const data = await voucherSignup({
      email: values.email,
      name: `${values.fname} ${values.sname}`,
      password: values.password.trim(),
      voucher: values.code,
    });
    if (data && data.token) {
      setLocalStorageItem("token", data.token);
      setLocalStorageItem("lastLoginTimestamp", `${Date.now()}`);
      setLocalStorageItem("showNotifications", "1");
      setLoginError("");
      launchLab();
      resetForm();
    } else {
      if (data.email) {
        setFieldError("email", data.email[0]);
      } else {
        setVoucherError();
      }
    }
    setSubmitting(false);
  };

  const initialValues = {
    email: "",
    password: "",
    reenteredPassword: "",
    fname: "",
    sname: "",
    code: code,
    acceptedTerms: false,
  };

  return (
    <div className="auth-layout d-flex flex-row justify-content-around">
      <div className="left-section d-flex flex-column justify-content-center">
        <div className="mt-4 left-section-signin d-flex flex-grow-1 flex-column align-items-center justify-content-center">
          <div className="login-header pb-2">WELCOME TO</div>
          <div className="login-logo d-flex justify-content-center mb-n1">
            <img src={authLogo} alt="authLogo" />
          </div>
          <div className="login-section mt-3 p-4 voucher-register-section">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandle}
            >
              <Form>
                <div className="d-flex flex-column">
                  <div className="login-section-header mb-4 text-center font-larger">
                    Register to redeem your voucher
                  </div>
                  <TextInput
                    name="email"
                    placeholder="Email"
                    required
                    className="border-none"
                    onFocus={resetLoginError}
                    inputGroupProps={{ className: "mb-2" }}
                    prepend={
                      <InputGroup.Text className="input-icon-background">
                        <img src={emailIcon} alt="emailIcon" />
                      </InputGroup.Text>
                    }
                  />
                  <div className="row">
                    <TextInput
                      name="fname"
                      placeholder="First Name"
                      required
                      onFocus={resetLoginError}
                      className="border-none"
                      groupProps={{ className: "col-sm-6" }}
                      inputGroupProps={{ className: "mb-2" }}
                      prepend={
                        <InputGroup.Text className="input-icon-background">
                          <img src={userIcon} alt="userIcon" />
                        </InputGroup.Text>
                      }
                    />
                    <TextInput
                      name="sname"
                      placeholder="Surname"
                      required
                      onFocus={resetLoginError}
                      className="border-none"
                      groupProps={{ className: "col-sm-6" }}
                      inputGroupProps={{ className: "mb-2" }}
                      prepend={
                        <InputGroup.Text className="input-icon-background">
                          <img src={userIcon} alt="userIcon" />
                        </InputGroup.Text>
                      }
                    />
                  </div>
                  <TextInput
                    name="password"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    className="border-none"
                    required
                    onFocus={resetLoginError}
                    inputGroupProps={{ className: "mb-2" }}
                    prepend={
                      <InputGroup.Text className="input-icon-background">
                        <img src={lockIcon} alt="passwordIcon" />
                      </InputGroup.Text>
                    }
                    append={
                      <InputGroup.Text className="input-icon-background cursor-pointer">
                        <img
                          onClick={changePasswordVisibility}
                          src={passwordVisible ? visibleIcon : invisibleIcon}
                          alt="invisibleIcon"
                        />
                      </InputGroup.Text>
                    }
                  />
                  <TextInput
                    name="reenteredPassword"
                    placeholder="Confirm Password"
                    type={reEnteredpassVisible ? "text" : "password"}
                    className="border-none"
                    required
                    onFocus={resetLoginError}
                    inputGroupProps={{ className: "mb-2" }}
                    prepend={
                      <InputGroup.Text className="input-icon-background">
                        <img src={lockIcon} alt="passwordIcon" />
                      </InputGroup.Text>
                    }
                    append={
                      <InputGroup.Text className="input-icon-background cursor-pointer">
                        <img
                          onClick={changeReEnteredPassVisibility}
                          src={
                            reEnteredpassVisible ? visibleIcon : invisibleIcon
                          }
                          alt="invisibleIcon"
                        />
                      </InputGroup.Text>
                    }
                  />
                  <TextInput
                    name="code"
                    disabled
                    onFocus={resetLoginError}
                    className="bg-white border-none"
                    prepend={
                      <InputGroup.Text className="input-icon-background">
                        <img
                          height="15"
                          width="20"
                          src={codeIcon}
                          alt="codeIcon"
                        />
                      </InputGroup.Text>
                    }
                  />
                  <CheckInput
                    custom
                    className="cursor-pointerer"
                    // onFocus={resetLoginError}
                    name="acceptedTerms"
                    label={
                      <div className="voucher-register-section_terms">
                        I accept the{" "}
                        <a href={TNC_URL} target="_blank">
                          Terms and Conditions
                        </a>{" "}
                        and consent to use of my personal information in
                        accordance with the{" "}
                        <a href={PRIVACY_URL} target="_blank">
                          Pure Storage Privacy Policy
                        </a>{" "}
                        <RequiredFieldSymbol />
                      </div>
                    }
                  />
                  {loginError && <div> {loginError} </div>}
                  <div className="login-section-submit mt-2">
                    <Button
                      variant="outline-secondary"
                      className="w-100"
                      type="submit"
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="redeem-voucher-register my-4 py-2 px-4">
            Already have an account?
            <NavLink
              to={`${USER_VOUCHER_ROUTE}/${code}/login`}
              className="text-decoration-none cursor-pointer "
            >
              {" "}
              Login
            </NavLink>
          </div>
        </div>
        <div className="terms-privacy-section mt-auto px-3 mb-4">
          <TermsAndPrivacySection />
        </div>
      </div>

      <div className="right-section">
        <img className="right-section-image w-100" src={auth} alt="auth" />
      </div>
    </div>
  );
};

export { VoucherRegister };
