import {
  toCamelCaseKeys,
  capitalizeFirstLetter,
  formatDateByDefault,
} from "../common";
import moment from "moment";
import { dateFormat } from "src/constants/common";

export const mapLab = (lab) => {
  lab = toCamelCaseKeys(lab);
  lab.status =
    (lab.isActive && "Enabled") ||
    (lab.isDraft && "Draft") ||
    (!lab.isActive && !lab.isDraft && "Disabled");
  return lab;
};

export const mapUserLabs = (labs = []) =>
  labs.map(toCamelCaseKeys).map((lab) => {
    const labCopy = { ...lab };
    labCopy.status =
      (lab.isActive && "Enabled") ||
      (lab.isDraft && "Draft") ||
      (!lab.isActive && !lab.isDraft && "Disabled");
    return labCopy;
  });

export const mapVoucherLabs = (labs = []) => labs.map(toCamelCaseKeys);

export const mapAdminLabs = (labs = []) =>
  labs.map(toCamelCaseKeys).map((lab) => {
    const labCopy = { ...lab };
    labCopy.status =
      (lab.isActive && "Enabled") ||
      (lab.isDraft && "Draft") ||
      (!lab.isActive && !lab.isDraft && "Disabled");

    labCopy.assignedUsers = lab.assignedUsers.map((user) => {
      return { ...user.user, expiry: user.expiry };
    });
    labCopy.assignedGroups = lab.assignedGroups.map((group) => {
      return { ...group.group, expiry: group.expiry };
    });
    labCopy.assignedCompanies = lab.assignedCompanies.map((company) => {
      return { ...company.company, expiry: company.expiry };
    });
    labCopy.vcForUsers = lab.vcForUsers.map((user) => {
      return { ...user.user, expiry: user.expiry };
    });
    labCopy.vcForGroups = lab.vcForGroups.map((group) => {
      return { ...group.group, expiry: group.expiry };
    });
    labCopy.vcForCompanies = lab.vcForCompanies.map((company) => {
      return { ...company.company, expiry: company.expiry };
    });

    return labCopy;
  });

export const mapAssignedLabs = (labs) => labs && labs.map(toCamelCaseKeys);

export const mapTemplate = (templates) =>
  templates?.map(toCamelCaseKeys)?.map((template) => template);

export const mapLabDetails = (labDetails, labTemplates, consoleVMs) => {
  const mappedLabDetails = {
    labName: labDetails.name,
    labKey: labDetails.alias,
    labTemplate:
      (labTemplates || []).find(
        (template) => template.name === labDetails.templateName
      )?.name || "",
    labDescription: labDetails.description,
    labAbout: labDetails.about,
    labImage: labDetails.image,
    labConsoleAccessVM:
      (consoleVMs || []).find((vm) => vm.name === labDetails.consoleVmName)
        ?.name || "",
    labProviderType: labDetails.labProviderType || "SKYTAP",
    labSwitchVMs: (labDetails.labProviderConfig?.consoleVms || [])
      .filter((vm) => vm.name !== labDetails.consoleVmName)
      .map((vm) => ({
        label: vm.name,
        value: vm.name,
      })),
    labStoryline: labDetails.storyline.id,
    sortOrderWt: labDetails.orderWeight,
    labPresentation: labDetails.presentation,
    tags: labDetails.tags,
    tagName: "",
    duplicateTag: "",
    customerInfoRequired: labDetails.customerInfoRequired,
    labUsageTimeout: labDetails.allowUsageTimeout,
    labUsageTimeoutValue: labDetails.usageTimeout,
    allowExtentions: labDetails.allowExtensionHours,
    allowExtentionHours: labDetails.allowedExtensionHours,
    accessList: getAccessList(labDetails),
    voucherConfigList: getVoucherList(labDetails),
    isDraft: labDetails.isDraft,
    isActive: labDetails.isActive,
    preLaunch: labDetails.preLaunch,
    latestStoryline: labDetails.latestStoryline,
  };

  return mappedLabDetails;
};

const getMappedUsers = (users) =>
  users.map(({ user, expiry }) => {
    return {
      id: user.id,
      fieldType: "user",
      type: capitalizeFirstLetter(user.type),
      name: user.name,
      email: user.email,
      numberOfUsers: 1,
      expiryDate: expiry,
    };
  });

const getMappedGroups = (groups) =>
  groups.map(({ group, expiry }) => {
    return {
      id: group.id,
      fieldType: "group",
      type: "Group",
      name: group.name,
      email: "",
      numberOfUsers: group.userCount,
      expiryDate: expiry,
    };
  });

const getMappedCompanies = (companies) =>
  companies.map(({ company, expiry }) => {
    return {
      id: company.id,
      fieldType: "company",
      type: "Company",
      name: company.name,
      email: "",
      numberOfUsers: company.userCount,
      expiryDate: expiry,
    };
  });

export const getAccessList = (labDetails) => {
  const userAccessList = labDetails.assignedUsers
    ? getMappedUsers(labDetails.assignedUsers)
    : [];

  const groupAccessList = labDetails.assignedGroups
    ? getMappedGroups(labDetails.assignedGroups)
    : [];

  const companyAccessList = labDetails.assignedCompanies
    ? getMappedCompanies(labDetails.assignedCompanies)
    : [];
  return [...userAccessList, ...groupAccessList, ...companyAccessList];
};

export const getVoucherList = (labDetails) => {
  const userVoucherList = labDetails.vcForUsers
    ? getMappedUsers(labDetails.vcForUsers)
    : [];

  const groupVoucherList = labDetails.vcForGroups
    ? getMappedGroups(labDetails.vcForGroups)
    : [];

  const companyVoucherList = labDetails.vcForCompanies
    ? getMappedCompanies(labDetails.vcForCompanies)
    : [];
  return [...userVoucherList, ...groupVoucherList, ...companyVoucherList];
};

export const getPayload = (lab, labTemplates, consoleVms, labImgUrl = null) => {
  // TODO: dynamic payload construction
  const payload = {
    name: lab.labName,
    description: lab.labDescription,
    about: lab.labAbout,
    template_id: labTemplates.find((val) => val.name === lab.labTemplate).id,
    console_vm_id: consoleVms.find((val) => val.name === lab.labConsoleAccessVM)
      .id,
    lab_provider_type: lab.labProviderType,
    lab_provider_config: {
      console_vms: [{ name: lab.labConsoleAccessVM }].concat(
        lab.labSwitchVMs.map((vm) => ({
          name: vm.value,
        }))
      ),
    },
    allow_usage_timeout: lab.labUsageTimeout,
    allow_extension_hours: lab.allowExtentions,
    is_active: lab.isActive,
    storyline: lab.labStoryline,
    presentation: lab.labPresentation.id,
    image: labImgUrl,
    latest_storyline: lab.latestStoryline,
    alias: lab.labKey,
  };

  if (lab.tags.length) {
    payload["tags"] = lab.tags;
  }

  if (lab.labUsageTimeout) {
    payload["usage_timeout"] = lab.labUsageTimeoutValue;
  }

  if (lab.allowExtentions) {
    payload["allowed_extension_hours"] = lab.allowExtentionHours;
  }

  payload["customer_info_required"] = lab.customerInfoRequired;

  let assignedCompany,
    assignedGroups,
    assignedUsers,
    voucherCompany,
    voucherGroups,
    voucherUsers = [] as any;

  assignedCompany = lab.accessList
    .filter((access) => access.fieldType === "company")
    .map((access) => {
      return {
        company: access.id,
        expiry: access.expiryDate
          ? formatDateByDefault(access.expiryDate)
          : null,
      };
    });

  assignedGroups = lab.accessList
    .filter((access) => access.fieldType === "group")
    .map((access) => {
      return {
        group: access.id,
        expiry: access.expiryDate
          ? formatDateByDefault(access.expiryDate)
          : null,
      };
    });
  assignedUsers = lab.accessList
    .filter((access) => access.fieldType === "user")
    .map((access) => {
      return {
        user: access.id,
        expiry: access.expiryDate
          ? formatDateByDefault(access.expiryDate)
          : null,
      };
    });

  voucherCompany = lab.voucherConfigList
    .filter((voucher) => voucher.fieldType === "company")
    .map((voucher) => {
      return {
        company: voucher.id,
        expiry: voucher.expiryDate
          ? formatDateByDefault(voucher.expiryDate)
          : null,
      };
    });
  voucherGroups = lab.voucherConfigList
    .filter((voucher) => voucher.fieldType === "group")
    .map((voucher) => {
      return {
        group: voucher.id,
        expiry: voucher.expiryDate
          ? formatDateByDefault(voucher.expiryDate)
          : null,
      };
    });
  voucherUsers = lab.voucherConfigList
    .filter((voucher) => voucher.fieldType === "user")
    .map((voucher) => {
      return {
        user: voucher.id,
        expiry: voucher.expiryDate
          ? formatDateByDefault(voucher.expiryDate)
          : null,
      };
    });

  payload["assigned_users"] = assignedUsers;
  payload["assigned_groups"] = assignedGroups;
  payload["assigned_companies"] = assignedCompany;
  payload["vc_for_users"] = voucherUsers;
  payload["vc_for_groups"] = voucherGroups;
  payload["vc_for_companies"] = voucherCompany;

  // if (!!lab.labStory) {
  //   payload["store"] = lab.labStory;
  // }

  if (lab.sortOrderWt) {
    payload["order_weight"] = lab.sortOrderWt;
  }
  return payload;
};

export const getResourcePayload = (data, resource, submittedResource) => {
  let totalUsers = 1;
  let prop = "totalUsers";
  if (resource.fieldType !== "user") {
    if (resource.fieldType === "group") {
      prop = "userCount";
    }
    totalUsers = submittedResource[prop]
      ? submittedResource[prop]
      : submittedResource.numberOfUsers;
  }

  const payload = {
    id: submittedResource.id,
    fieldType: resource.fieldType,
    type: capitalizeFirstLetter(resource.fieldType),
    name: submittedResource.name,
    email: submittedResource.email,
    numberOfUsers: totalUsers,
    expiryDate:
      data.expiry === "Custom" ? formatDateByDefault(data.expiryDate) : "",
  };

  return payload;
};

export const launchConsole = (route, history, data, state?) => {
  if (state) {
    history.push(`${route}?id=${data.gid}`, state);
  } else {
    history.push(`${route}?id=${data.gid}`);
  }
};

export const mapStorylinesToVersion = (storylines) =>
  storylines && storylines.map((sl) => sl.versions).flat();

export const mapVersions = (versions) => versions.map(toCamelCaseKeys);

export const getActiveVersions = (versions) =>
  versions && versions.filter((version) => !version.isDraft);

export const getLabInitials = (labName) => {
  const words = labName.split(" ");
  let char;
  return words.reduce((initials, word) => {
    char = word.charAt(0);
    if (char.match(/[a-z]/i)) {
      initials += char.toUpperCase();
    } else if (char.match(/[0-9]/)) {
      initials += word.substring(0, 4);
    }
    return initials;
  }, "");
};
