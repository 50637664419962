import React from "react";

import "./styles.scss";
import { Button } from "react-bootstrap";
import NotificationIcon from "src/assets/svgrImages/NotificationIcon";
import { cx } from "@emotion/css";
import { readAllNotificationsApi } from "src/api/notifications";
import useClickOutside from "src/hooks/useClickOutside";
import Badge from "src/components/ui/Badge";
import {
  computePastTime,
  readNotificationHelper,
} from "src/components/AppHeader/NotificationList/helpers";
import Pagination from "react-bootstrap/Pagination";
import usePagination from "src/hooks/usePagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import {
  getUserNotifications,
  readNotificationThunk,
} from "src/state/userNotifications/actions";
import { IUserNotification } from "src/state/userNotifications/reducer";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";

type Notification = IUserNotification & {
  pastTime: string;
};

const NotificationList = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const toggleShowNotifications = () =>
    setShowNotifications(!showNotifications);
  const closeShowNotifications = () => setShowNotifications(false);

  const listRef = React.useRef(null);
  const badgeRef = React.useRef(null);

  const { notificationAction } = useSelector(
    (state: RootState) => state.notifications
  );

  const { list, userNotificationCounter } = useSelector(
    (state: RootState) => state.userNotifications
  );

  useClickOutside([badgeRef, listRef], closeShowNotifications);

  const itemsPerPage = 10;
  const { page, handlers, limits, pagesInSet } = usePagination(
    notifications.length,
    itemsPerPage
  );

  const transformNotifications = (items) =>
    items
      .filter(
        (item) =>
          (item.displayLocation === "notification_area" ||
            (item.displayLocation === "on_next_login" && item.isRead)) &&
          item.isEnabled &&
          new Date(item.startTime).getTime() <= new Date().getTime() &&
          new Date().getTime() <= new Date(item.endTime).getTime()
      )
      .map((item) => ({
        ...item,
        pastTime: computePastTime(item.startTime),
      }));

  const setupNotifications = async () => {
    // @ts-ignore
    const items: IUserNotification[] = await dispatch(getUserNotifications());
    setNotifications(transformNotifications(items));
  };

  React.useEffect(() => {
    setupNotifications();
  }, [notificationAction]);

  React.useEffect(() => {
    setNotifications(transformNotifications(list));
  }, [userNotificationCounter]);

  const readNotification = (notificationId: string, isRead: boolean) => {
    if (!isRead) {
      const list = readNotificationHelper(notifications, notificationId);
      setNotifications(list);
      dispatch(readNotificationThunk(notificationId, false));
    }
  };

  const readAllNotifications = () => {
    readAllNotificationsApi();
    const list = notifications.map((item) => ({ ...item, isRead: true }));
    setNotifications(list);
  };

  const firstItem = itemsPerPage * (page - 1);
  const lastItem = page * itemsPerPage;

  const unreadNotifications = notifications.filter(
    (item) => !item.isRead
  ).length;

  return (
    <>
      <Badge
        variant="count"
        items={unreadNotifications}
        onClick={toggleShowNotifications}
        ref={badgeRef}
      >
        <NotificationIcon transform="scale(0.8)" />
      </Badge>
      <div
        className={cx(
          "pure-notification-arrow",
          showNotifications ? "d-block" : "d-none"
        )}
      />
      <div
        ref={listRef}
        className={cx(
          "notification-list",
          showNotifications ? "d-block" : "d-none"
        )}
      >
        <div className="list-header">
          <span className="list-title"> Notifications </span>
          <Button
            size="sm"
            variant="link"
            className="read-all"
            disabled={notifications.length === 0}
            onClick={readAllNotifications}
          >
            {" "}
            Mark all as read{" "}
          </Button>
        </div>
        <div
          className={cx("list-container", {
            "center-child": notifications.length === 0,
          })}
        >
          {notifications.length ? (
            notifications
              .slice(firstItem, lastItem)
              .map((notification, index) => (
                <div
                  key={index}
                  className={cx("list-item-container", {
                    "list-item-read": notification.isRead,
                  })}
                >
                  <div
                    className={cx(
                      "link-btn",
                      "list-item",
                      !notification.isRead && "list-item-unread cursor-pointer"
                    )}
                    onClick={readNotification.bind(
                      null,
                      notification.id,
                      notification.isRead
                    )}
                  >
                    <Badge
                      variant={notification.isRead ? "neutral" : "dot"}
                      badgeStyle="filled"
                    >
                      <NotificationIcon transform="scale(0.7)" />
                    </Badge>
                    <div className="notification">
                      <div className="notification-header">
                        <div> {notification.header} </div>
                        <span>{notification.pastTime}</span>
                      </div>
                      <div className="notification-body">
                        {" "}
                        {notification.body}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoSearchResultFound
              message="No current notifications"
              errImgSrc="no-notification.svg"
            />
          )}
        </div>
        {notifications.length > 10 && (
          <div className="list-footer">
            <Pagination>
              <Pagination.First
                disabled={!limits.canOpenPrevPage}
                onClick={handlers.openFirstPage}
              />
              <Pagination.Prev
                className={limits.canOpenPrevPage ? "d-inline" : "d-none"}
                onClick={handlers.openPrevPage}
              />
              <Pagination.Ellipsis
                disabled={notifications.length === 0}
                className={limits.canOpenPrevPageSet ? "d-inline" : "d-none"}
                onClick={handlers.openPrevPageSet}
              />
              {pagesInSet.map((thisPage, index) => (
                <Pagination.Item
                  key={index}
                  active={page === thisPage}
                  disabled={page === thisPage}
                  onClick={handlers.goToPage.bind(null, thisPage)}
                >
                  {thisPage}
                </Pagination.Item>
              ))}
              <Pagination.Ellipsis
                disabled={notifications.length === 0}
                className={limits.canOpenNextPageSet ? "d-inline" : "d-none"}
                onClick={handlers.openNextPageSet}
              />
              <Pagination.Next
                className={limits.canOpenNextPage ? "d-inline" : "d-none"}
                onClick={handlers.openNextPage}
              />
              <Pagination.Last
                disabled={!limits.canOpenNextPage}
                onClick={handlers.openLastPage}
              />
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationList;
