import React, { useState, useEffect } from "react";
import "./LabsList.scss";
import Table from "src/components/shared/Table";
import SearchBox from "src/components/shared/SearchBox";
import NoSearchResultFound from "src/components/ui/NoSearchResultFound";
import { ILab } from "src/state/labs/reducer";
import { RootState } from "src/state/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { getLabs } from "src/state/labs/actions";
import { getLabsColumns } from "src/containers/admin/labs/LabsList/labsColumns";
import AccessList from "./AccessList";
import { enableLab, deleteLabById } from "src/api/lab";
import WarningModal from "src/components/shared/WarningModal";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  ADMIN_ROUTE,
  ADMIN_NEW_LAB_ROUTE,
  ADMIN_EDIT_LAB_ROUTE,
} from "src/constants/appRoutes";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";

type LabsListProps = {};

const LabsList: React.FC<LabsListProps> = (props) => {
  const [searchBy, setSearchBy] = useState("");
  const [filteredLabs, setFilteredLabs] = useState<ILab[]>([]);
  const [accessListDetails, setAccessListDetails] = useState({});
  const [displayAccessList, setDisplayAccessList] = useState(false);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [selectedLabId, setSelectedLabId] = useState("");

  let history = useHistory();
  const dispatch = useDispatch();
  const labs: ILab[] = useSelector((state: RootState) => state.labs.list);
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount);
  const isLoading = loaderCount > 0;

  useEffect(() => {
    dispatch(getLabs());
  }, []);

  const getLabNameById = (labId) => {
    const lab = labs.find((lab) => lab.id === labId);
    return lab && lab.name ? lab.name : "";
  };

  const showAccessList = (row) => {
    const data = row.original;
    let { assignedUsers, assignedGroups, assignedCompanies } = data;
    setAccessListDetails({
      assignedUsers,
      assignedGroups,
      assignedCompanies,
      labName: data.name,
    });
    setDisplayAccessList(true);
  };

  const activateLab = async (lab, flag) => {
    const {
      id,
      name,
      templateId,
      consoleVmId,
      orderWeight,
      storyline,
      presentation,
    } = lab;
    const payload = {
      name: name,
      template_id: templateId,
      console_vm_id: consoleVmId,
      order_weight: orderWeight,
      is_active: flag,
      storyline: storyline.id,
      presentation: presentation.id,
    };

    let response = await enableLab(payload, id);
    if (response && !response.isAxiosError) {
      dispatch(getLabs());
    }
  };

  const closeDeleteGroupModal = () => {
    setSelectedLabId("");
    setShowDeleteWarningModal(false);
  };

  const displayDeleteLabModal = (lab) => {
    setSelectedLabId(lab.id);
    setShowDeleteWarningModal(true);
  };

  const deleteLab = async () => {
    const data = await deleteLabById(selectedLabId);
    if (data) {
      dispatch(getLabs());
      closeDeleteGroupModal();
    }
  };

  const editLab = (labId) => {
    history.push(`${ADMIN_ROUTE}${ADMIN_EDIT_LAB_ROUTE}/${labId}`);
  };

  const labsColumns = getLabsColumns(
    showAccessList,
    activateLab,
    displayDeleteLabModal,
    editLab
  );

  const filterLabList = (searchQuery) => {
    let filteredLabs = labs.filter((lab) => {
      if (!searchQuery) return true;
      const name = lab.name && lab.name.toLowerCase();
      const tags = lab.tags && lab.tags.toString().toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (name && name.includes(query)) || (tags && tags.includes(query));
    });
    setFilteredLabs(filteredLabs);
  };

  useEffect(() => {
    filterLabList(searchBy);
  }, [labs]);

  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery);
    filterLabList(updatedSearchQuery);
  };

  const createLab = () => {
    history.push(`${ADMIN_ROUTE}${ADMIN_NEW_LAB_ROUTE}`);
  };

  return (
    <div className="labs-list-section d-flex flex-column flex-grow-1">
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteGroupModal}
          onConfirmAction={deleteLab}
        >
          <div className="ml-2">
            <div>Are you sure you want to delete</div>
            <div>
              <b>{getLabNameById(selectedLabId)}</b> ?
            </div>
          </div>
        </WarningModal>
      )}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`labs-list-section-header`}>
          <span>
            <b>Labs</b>
          </span>
        </div>
        <div className="d-flex align-self-center">
          <div className="labs-list-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by name or tags"
            />
          </div>
          {filteredLabs.length ? (
            <Button
              type="submit"
              size="sm"
              className="ml-2"
              variant="primary"
              onClick={createLab}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create Lab </span>
              </div>
            </Button>
          ) : null}
        </div>
      </div>
      {filteredLabs.length ? (
        <Table searchBy={searchBy} columns={labsColumns} data={filteredLabs} />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant lab name or tags"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <>
            <NoSearchResultFound
              message="No Labs Created!"
              detail="Click below to create a new lab"
              errImgSrc="no-lab.svg"
            />
            <Button
              type="submit"
              size="sm"
              className="ml-2 no-result-add-btn"
              variant="primary"
              onClick={createLab}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create Lab </span>
              </div>
            </Button>
          </>
        )
      )}

      {displayAccessList ? (
        <AccessList
          setShowModal={setDisplayAccessList}
          data={accessListDetails}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LabsList;
