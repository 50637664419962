import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ReactComponent as ActiveLab } from "src/assets/images/active-lab.svg";
import { ReactComponent as AllLab } from "src/assets/images/all-lab.svg";
import { ReactComponent as AllLabActiveTab } from "src/assets/images/all-labs-active-tab.svg";
import { ReactComponent as ActiveLabActiveTab } from "src/assets/images/active-labs-active-tab.svg";
import "./Labs.scss";
import SearchBox from "../shared/SearchBox";
import { fetchUserLabs } from "src/api/lab";
import { mapUserLabs } from "src/helpers/labs";
import LabCard from "src/containers/LabCard";
import NoSearchResultFound from "../ui/NoSearchResultFound";
import { toCamelCaseKeys } from "src/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { setSidebar } from "src/state/sidebar/actions";
import { RootState } from "src/state/rootReducer";
import {RouteComponentProps} from "react-router-dom";

type LabsProps = {} & RouteComponentProps;

const Labs: React.FC<LabsProps> = (props) => {
  const [labs, setLabs] = useState<Array<any>>([]);
  const [searchVal, setSearchVal] = useState("");
  const [tabKey, setTabKey] = useState("allLabs");
  const dispatch = useDispatch();
  const sidebar = useSelector((state: RootState) => state.sidebar);

  const getLabs = async () => {
    let rawData = await fetchUserLabs();
    let data = toCamelCaseKeys(rawData);
    setLabs([
      ...mapUserLabs(data.activeLabs),
      ...mapUserLabs(data.assignedLabs),
    ]);

    if (data.activeLabs.length > 0) {
      handleTabChange("activeLabs");
    } else {
      handleTabChange("allLabs");
    }
  };

  useEffect(() => {
    sidebar.sidebarOpen && dispatch(setSidebar());
    getLabs();
  }, []);

  const handleSearch = (value) => {
    setSearchVal(value);
  };
  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const ShowLabs = ({ labType }) => {
    const formatName = (lab) =>
      (lab.name || lab.lab.name)
        .toLowerCase()
        .includes(searchVal?.toLowerCase());

    const mappedLabs = labs
      .filter((lab) =>
        labType === "activeLabs"
          ? lab.isActive && formatName(lab)
          : formatName(lab)
      )
      .map((lab, id) => <LabCard key={id} activeTab={labType} data={lab} />);

    return mappedLabs.length ? (
      <div className="card-container mt-4">{mappedLabs}</div>
    ) : (
      <NoSearchResultFound
        message={labType === "activeLabs" ? "No Active Labs" : "No Labs Found"}
        errImgSrc={
          labType === "activeLabs" ? "no-active-lab.svg" : "no-my-lab.svg"
        }
      />
    );
  };

  return (
    <div className="d-flex mb-5 flex-grow-1">
      <div className="lab-card d-flex flex-column flex-shrink-1 flex-grow-1">
        <h5 className="ml-1 mb-5 mt-4">
          <b>Labs</b>
        </h5>
        <div className="d-flex justify-content-end lab-card-outer-section">
          <div className="users-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearch}
              placeholder="Search by lab name"
            />
          </div>
        </div>
        <Tabs
          activeKey={tabKey}
          transition={false}
          id="userLabs"
          onSelect={handleTabChange}
        >
          <Tab
            eventKey="activeLabs"
            title={
              <div className="d-flex align-items-center">
                {tabKey === "activeLabs" ? (
                  <ActiveLabActiveTab height="20" width="20" />
                ) : (
                  <ActiveLab height="20" width="20" />
                )}
                <div className="ml-1"> Active Labs</div>
              </div>
            }
          >
            <ShowLabs labType="activeLabs" />
          </Tab>
          <Tab
            eventKey="allLabs"
            title={
              <div className="d-flex align-items-center">
                {tabKey === "allLabs" ? (
                  <AllLabActiveTab height="20" width="20" />
                ) : (
                  <AllLab height="20" width="20" />
                )}
                <div className="ml-1"> All Labs</div>
              </div>
            }
          >
            <ShowLabs labType="allLabs" />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
