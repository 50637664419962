import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import {
  startVmPollingAction,
  stopVmPollingAction,
} from "src/state/ui/actions";
import {
  extendLabTime,
  getActiveLabById,
  getLabVmURL,
  getVmStatus,
} from "src/api/lab";
import usePolling from "src/hooks/usePolling";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "./Console.scss";
import useWindowSize from "src/hooks/useWindowSize";
import PreviewSection from "src/containers/admin/StoryLine/PreviewSection";
import leftArrowIcon from "src/assets/images/left-arrow.svg";
import { RootState } from "src/state/rootReducer";
import { mapLab } from "src/helpers/labs";
import AppHeader from "src/components/AppHeader";
import { pushNotification } from "../ui/Notification";
import {formatError, getLocalStorageItem, isEmpty} from "src/utils/common";
import { LABS_ROUTE } from "src/constants/appRoutes";
import NewWindow from "react-new-window";
import screenfull from "screenfull";
import PureModal from "src/components/shared/PureModal";
import { DropdownOption } from "src/components/AppHeader/AppHeader";

type ConsoleProps = {} & RouteComponentProps;

const Console: React.FC<ConsoleProps> = (props) => {
  const [width, height] = useWindowSize();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [killPollingInterval, setKillPollingInterval] = useState(false);
  const urlParams = new URLSearchParams(search);
  const labId = urlParams.get("id");
  const consoleVmName = urlParams.get("console_vm");
  const consoleEle = document.getElementById("console");
  const [isPortalFullWidth, setIsPortalFullWidth] = React.useState(false);

  const [popupWarning, setPopWarning] = useState(false);
  const togglePopupWarning = () => setPopWarning(!popupWarning);

  const dispatch = useDispatch();

  const consoleRef = React.useRef<HTMLDivElement | null>(null);

  const isFullScreen =
    window.innerWidth === window.screen.width &&
    window.innerHeight === window.screen.height;

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  const [labDetails, setLabDetaills] = useState<any>(null);
  const [openWidget, setWidgetState] = useState(true);
  const toggleWidgetState = () => {
    if (widgetPortalState) {
      setWidgetPortalState(!widgetPortalState);
    }
    setWidgetState(!openWidget);
  };

  const [widgetPortalState, setWidgetPortalState] = React.useState(false);
  const toggleWidgetPortalState = () => {
    toggleWidgetState();
    setWidgetPortalState(!widgetPortalState);
  };

  const onPollingStart = () => {
    setShowLoader(true);
    dispatch(startVmPollingAction());
  };

  const polling = async () => {
    const data = await getVmStatus(labId);
    if (data && data.state === "running") {
      setShowLoader(false);
      dispatch(stopVmPollingAction());
      setKillPollingInterval(true);
    }

    if (!data) {
      setShowLoader(false);
      dispatch(stopVmPollingAction());
      setKillPollingInterval(true);
    }
  };

  usePolling(onPollingStart, polling, 10000, killPollingInterval);

  const vmPollingStatus: boolean = useSelector(
    (state: RootState) => state.ui.vmPollingStatus
  );

  const getLabDetails = async () => {
    let labDetails;
    if (consoleVmName && labId) {
      labDetails = await getLabVmURL(labId, consoleVmName);
    } else if (labId) {
      labDetails = await getActiveLabById(labId);
    }

    if (isEmpty(labDetails) || labDetails?.hasError) {
      pushNotification({
        type: "error",
        message: formatError(labDetails),
      });
      history.push(LABS_ROUTE);
    }
    labDetails = mapLab(labDetails)
    setLabDetaills(labDetails);
    return labDetails
  };

  const resizeWindow = async () => {
    const consoleWidth = consoleEle?.clientWidth || width;
    const consoleHeight = consoleEle?.clientHeight || height;
    if (
      consoleWidth > 100 &&
      consoleHeight > 100 &&
      !vmPollingStatus &&
      !showLoader
    ) {
      return await extendLabTime(
        labId,
        labDetails?.consoleUrl,
        consoleWidth,
        consoleHeight,
        true,
        false
      );
    }
  };

  useEffect(() => {
    resizeWindow();
  }, [width, height, openWidget]);

  let calledResizeTimes = 1
  let resizeOnLaunchInterval: any = null

  const resizeOnLaunch = async (consoleUrl) => {
    if (calledResizeTimes % 5 === 0) {
      const consoleEle = document.getElementById("console");
      extendLabTime(
          labId,
          consoleUrl,
          consoleEle?.clientWidth || 1024,
          consoleEle?.clientHeight || 579,
          true,
          false
      );
    }
    ++calledResizeTimes
    if (calledResizeTimes > 30) {
      clearInterval(resizeOnLaunchInterval)
    }
  };

  useEffect(() => {
    if (labId) {
      getLabDetails().then(lab => {
        if (!vmPollingStatus && !showLoader && !!lab && lab?.consoleUrl && !!labDetails) {
          resizeOnLaunchInterval = setInterval(() => resizeOnLaunch(lab?.consoleUrl), 1000)
        }
      })
    }
    return () => {
      if (resizeOnLaunchInterval) {
        clearInterval(resizeOnLaunchInterval)
      }
    }
  }, [vmPollingStatus, labId]);

  useEffect(() => {
    const handleFullScreen = (event) => {
      const keyCode = event.keyCode || event.which;
      if (keyCode === 122) {
        event.preventDefault();
        event.stopPropagation();
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      }
    };
    document.addEventListener("keydown", handleFullScreen);
    return () => {
      document.removeEventListener("keydown", handleFullScreen);
    };
  }, []);

  const handlePortalOpen = (portalWindow) => {
    // on MacOS, portalWindow.outerWidth comes out to be 427 without using setTimeout which is invalid
    // because on MacOS, first window open with width 427 and than with > 427
    setTimeout(() => {
      setIsPortalFullWidth(portalWindow.outerWidth > 427);
    }, 100);
  };

  const switchLabVM = async (vm: DropdownOption) => {
    if (labId && vm?.value) {
      const data = await getLabVmURL(labId, vm.value);
      setLabDetaills(data);
    }
  };

  const labVMOptions = labDetails?.lab?.labProviderConfig?.consoleVms?.map(
    (vm) => ({
      label: vm.name,
      value: vm.name,
    })
  );

  return (
    <div ref={consoleRef} className="console-container">
      <PureModal
        title="Alert"
        primaryBtnLabel="Ok"
        showModal={popupWarning}
        primaryBtnAction={togglePopupWarning}
        closeModal={togglePopupWarning}
        className="highest-ZIndex preview-section-modal"
      >
        <span>
          Your browser permissions restricts to pop out the guide window. Please
          update the browser settings then try again.
        </span>
      </PureModal>
      <AppHeader
        lab={labDetails}
        isFullScreen={isFullScreen}
        onToggleFullScreen={toggleFullScreen}
        labVMOptions={labVMOptions}
        switchLabVm={switchLabVM}
      />
      {pathname.includes("presentation") ? (
        // <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <iframe
          id="console"
          className="console-presentation"
          title="googleSlides"
          width="100%"
          src={labDetails?.lab?.presentation?.webViewLink}
          frameBorder="0"
          allowFullScreen={true}
        />
      ) : (
        // </div>
        <div style={{ height: "calc(100% - 3.8rem)", width: "100%" }}>
          {showLoader ? (
            <div className="loader">
              <Spinner
                animation="border"
                style={{ color: "var(--color-primary)" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
              <span style={{ fontSize: "1.3rem", color: "black" }}>
                Connecting to your Lab
              </span>
              <span style={{ color: "black" }}>
                This may take a minute or two…
              </span>
            </div>
          ) : (
            <div className="console-widget d-flex flex-row justify-content-end">
              <iframe
                title="Console"
                src={labDetails?.consoleUrl}
                className="console"
                id="console"
              />
              {openWidget ? (
                <div className="console-widget-opened">
                  <PreviewSection
                    includeMinimise={true}
                    widgetPortalState={widgetPortalState}
                    onTogglePortal={toggleWidgetPortalState}
                    toggleWidgetState={toggleWidgetState}
                    storyLine={labDetails?.lab?.storyline}
                    height="calc(100vh - 64px)"
                  />
                </div>
              ) : (
                <div
                  className="console-widget-closed cursor-pointer"
                  onClick={toggleWidgetState}
                >
                  <div className="half-circle"> </div>
                  <div className="lines">
                    <img src={leftArrowIcon} alt="left-arrow-widget" />
                    <b> Guide </b>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {widgetPortalState && (
        <NewWindow
          features={{
            width: 427,
            height: window.screen.height - 150,
            overflow: "hidden",
            rel: "noopener",
            location: 0,
          }}
          name={labId || "_blank"}
          onBlock={togglePopupWarning}
          onOpen={handlePortalOpen}
        >
          <div
            className="portal-window"
            style={{
              width: isPortalFullWidth ? 427 : "unset",
            }}
          >
            <PreviewSection
              includeMinimise={true}
              toggleWidgetState={toggleWidgetState}
              widgetPortalState={widgetPortalState}
              onTogglePortal={toggleWidgetPortalState}
              storyLine={labDetails?.lab?.storyline}
              height="100vh"
            />
          </div>
        </NewWindow>
      )}
    </div>
  );
};

export default Console;
