import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { setPassword, verifyUser } from "src/api/users";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import authLogo from "src/assets/images/authLogo.svg";
import lockIcon from "src/assets/images/lockIcon.svg";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import FormGroup from "react-bootstrap/FormGroup";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import invisibleIcon from "src/assets/images/invisibleIcon.svg";
import auth from "src/assets/images/auth.png";
import { PasswordRegex } from "src/constants/regex";
import { LOGIN_ROUTE, SET_USER_PASSWORD_ROUTE } from "src/constants/appRoutes";
import { pushNotification } from "src/components/ui/Notification/index";
import { isEmpty, formatError } from "src/utils/common";
import "./SetPassword.scss";

type SetPasswordProps = {};

const initialValues = { password: "", reenteredPassword: "" };
const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short, it must contain at least 8 characters")
    .max(100, "Your password cannot exceed a maximum of 100 characters")
    .matches(PasswordRegex, {
      message:
        "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol",
    }),
  reenteredPassword: Yup.string()
    .required("Password is required")
    .test(
      'match-password',
      'Passwords do not match',
      function (this, value) {
        return !!value?.trim() && value.trim() === this.parent.password.trim()
      }
    )
  // .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

const SetPassword: React.FC<SetPasswordProps> = () => {
  const [submitError, setSubmitError] = useState<String>();
  const { uid, token } = useParams();
  const history = useHistory();
  const location = useLocation();
  let [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  let [reEnteredpassVisible, toggleReEnteredPassVisibility] = useState<Boolean>(
    false
  );

  const changePasswordVisibility = (e) =>
    togglePasswordVisibility(!passwordVisible);

  const changeReEnteredPassVisibility = (e) =>
    toggleReEnteredPassVisibility(!reEnteredpassVisible);

  const verifyUserCreds = async () => {
    const data = await verifyUser(uid, token);
    const route = location.pathname.split("/")[1];
    if (isEmpty(data) || data.hasError) {
      pushNotification({
        type: "error",
        message:
          route === SET_USER_PASSWORD_ROUTE.substring(1)
            ? "The link is expired or invalid. Please contact admin for further assistance."
            : "The account activation link is expired or invalid. Please contact admin for further assistance.",
      });
      history.push(LOGIN_ROUTE);
    } else {
      route !== SET_USER_PASSWORD_ROUTE.substring(1) &&
        pushNotification({
          type: "success",
          message:
            "Your account is active. Please set your password to proceed further.",
        });
    }
  };

  useEffect(() => {
    verifyUserCreds();
  }, []);

  const changePassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = await setPassword(uid, token, { password: values?.password?.trim() });
    if (isEmpty(data) || data.hasError) {
      setSubmitError(formatError(data));
    } else {
      history.push(LOGIN_ROUTE);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={changePassword}
    >
      {(formik: any) => (
        <div className="auth-layout d-flex flex-row justify-content-around">
          <div className="set-password set-password-left-section d-flex flex-grow-1 flex-column align-items-center justify-content-center">
            <div className="login-header pb-2">WELCOME TO</div>
            <div className="login-logo d-flex justify-content-center">
              <img src={authLogo} alt="authLogo" />
            </div>
            <div className="login-section mt-4">
              <div className="login-section-header mb-3">Set Password</div>
              <p className="login-section-text">Enter your new password</p>
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup
                  controlId="forPassword"
                  className="set-password-form-input"
                >
                  <InputGroup className="mb-3 mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="input-icon-background">
                        <img src={lockIcon} alt="lockIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="password"
                      placeholder="Password"
                      className="border-none"
                      type={passwordVisible ? "text" : "password"}
                      aria-describedby="basic-addon1"
                      {...formik.getFieldProps("password")}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="input-icon-background cursor-pointer">
                        <img
                          onClick={changePasswordVisibility}
                          src={passwordVisible ? visibleIcon : invisibleIcon}
                          alt="invisibleIcon"
                        />
                      </InputGroup.Text>
                    </InputGroup.Append>
                    {formik.errors.password && formik.touched.password ? (
                      <div className="w-100 mt-1">
                        <InputErrorMessage
                          errorMessage={formik.errors.password || ""}
                        />
                      </div>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  controlId="forReEnteredPassword"
                  className="section-form-input"
                >
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="input-icon-background">
                        <img src={lockIcon} alt="lockIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      id="reenterPassword"
                      placeholder="Re-enter Password"
                      className="border-none"
                      aria-describedby="basic-addon1"
                      type={reEnteredpassVisible ? "text" : "password"}
                      {...formik.getFieldProps("reenteredPassword")}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="input-icon-background cursor-pointer">
                        <img
                          onClick={changeReEnteredPassVisibility}
                          src={
                            reEnteredpassVisible ? visibleIcon : invisibleIcon
                          }
                          alt="invisibleIcon"
                        />
                      </InputGroup.Text>
                    </InputGroup.Append>
                    {formik.errors.reenteredPassword &&
                    formik.touched.reenteredPassword ? (
                      <div className="w-100 mt-1">
                        <InputErrorMessage
                          errorMessage={formik.errors.reenteredPassword || ""}
                        />
                      </div>
                    ) : null}
                  </InputGroup>
                </FormGroup>
                {submitError ? (
                  <div className="error-message login-error-message">
                    {submitError}
                  </div>
                ) : null}
                <div className="mt-4">
                  <Button
                    variant="outline-primary"
                    className="w-100 mb-4 btn-bg-white"
                    type="submit"
                  >
                    Set Password
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="right-section">
            <img className="right-section-image w-100" src={auth} alt="auth" />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SetPassword;
