import React, { useEffect, useState, useMemo } from "react";
import LabsDropdown from "src/components/SideBar/LabsDropdown";
import flask from "src/assets/images/flask.svg";
import submitTicket from "src/assets/images/help.svg";
import "./SideBar.scss";
import { NavLink } from "react-router-dom";
import { fetchUserLabs } from "src/api/lab";
import { mapUserLabs } from "src/helpers/labs";
import CustomTooltip from "src/components/ui/CustomTooltip";
import voucherIcon from "src/assets/images/voucher.svg";
import dropdownIcon from "src/assets/images/dropdown.svg";
import dropdownActiveIcon from "src/assets/images/sort-up-white.svg";
import consoleDarkIcon from "src/assets/images/console-dark.svg";
import presentationDarkIcon from "src/assets/images/presentation-dark.svg";
import {
  LABS_ROUTE,
  VOUCHERS_ROUTE,
  SUBMIT_TICKET_ROUTE,
  USER_STORYLINE_ROUTE,
} from "src/constants/appRoutes";
import LaunchLab from "../Labs/LaunchLab";
import ViewPresentation from "../Labs/Presentation";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { setSidebar } from "src/state/sidebar/actions";
import {
  checkAdminUser,
  getLocalStorageItem,
  parseJwtToken,
} from "src/utils/common";
import { ReactComponent as ActiveStatus } from "src/assets/images/active-status.svg";
import { IStoryline } from "src/state/storyline/reducer";
import { getStorylineList } from "src/state/storylineList/actions";

type SideBarProps = {};

const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const sidebar = useSelector((state: RootState) => state.sidebar);

  const dispatch = useDispatch();
  const toggleSidebar = () => {
    dispatch(setSidebar());
  };
  const [labsOpen, setLabsOpen] = React.useState<Boolean>(false);
  const [labs, setLabs] = useState<Array<any>>([]);
  const toggle = () => setLabsOpen(!labsOpen);
  const token = getLocalStorageItem("token");
  const parsedToken = useMemo(() => parseJwtToken(token), [token]);
  const showVoucherAccessTab = parsedToken?.voucher_access;

  const storylines: Array<IStoryline> | null = useSelector(
    (state: RootState) => state.storylineList.list
  );

  const getLabs = async () => {
    let data = await fetchUserLabs();
    setLabs([
      ...mapUserLabs(data.active_labs),
      ...mapUserLabs(data.assigned_labs),
    ]);
  };

  useEffect(() => {
    sidebar.sidebarOpen && getLabs();
    if (!parsedToken.admin_access) {
      if (!storylines) {
        dispatch(getStorylineList());
      }
    }
  }, []);

  const listLabs = (labType) => {
    const formatName = (lab) => (lab.name || lab.lab.name).toLowerCase();

    return labs
      .filter((lab) =>
        labType === "activeLabs"
          ? lab.isActive && formatName(lab)
          : !lab.isActive && formatName(lab)
      )
      .map((lab, idx) => (
        <div className="dropdown-opts" key={idx}>
          <CustomTooltip text={lab.name || (lab && lab.lab.name)}>
            <span className="dropdown-opts_text overflow-ellipsis">
              {lab.name || (lab && lab.lab.name)}
            </span>
          </CustomTooltip>
          <ViewPresentation data={lab} toggleSideBar={toggleSidebar}>
            {(viewPresentation) => (
              <CustomTooltip text="Presentation">
                <span className="dropdown-opts_img">
                  <img
                    src={presentationDarkIcon}
                    alt="presentation"
                    width="22"
                    onClick={viewPresentation}
                  />
                  {lab.isActive && (
                    <ActiveStatus height="6" width="6" className="mb-3 ml-n2" />
                  )}
                </span>
              </CustomTooltip>
            )}
          </ViewPresentation>
          <LaunchLab lab={lab}>
            {(prepareLaunch) => (
              <CustomTooltip text={lab.isActive ? "Console" : "Launch"}>
                <span className="dropdown-opts_img">
                  <img
                    src={consoleDarkIcon}
                    alt="console"
                    width="22"
                    onClick={prepareLaunch}
                  />
                </span>
              </CustomTooltip>
            )}
          </LaunchLab>
        </div>
      ));
  };

  return (
    <>
      <div className="sidebar d-flex flex-row">
        <div
          className={`sidebar-icon sidebar-navigator ${sidebar && "slide-in"}`}
        >
          <div
            className={`sidebar-item ${labsOpen && "sidebar-active mb-3"}`}
            onClick={toggle}
          >
            <div className="sidebar-dropdown sidebar-item-row overflow-ellipsis">
              <img
                className="sidebar-item-icon"
                height="18"
                width="18"
                src={flask}
                alt="flask"
              />
              <NavLink
                to={LABS_ROUTE}
                onClick={toggleSidebar}
                className="sidebar-item-name sidebar-dropdown-name"
              >
                Labs
              </NavLink>
              {labsOpen ? (
                <img
                  src={dropdownActiveIcon}
                  alt="dropdown-active"
                  className="sidebar-dropdown-toggle toggle"
                />
              ) : (
                <img
                  src={dropdownIcon}
                  alt="dropdown"
                  className="sidebar-dropdown-toggle toggle"
                />
              )}
            </div>
          </div>
          {labsOpen && (
            <>
              <LabsDropdown
                initiallyOpen={false}
                lab="Active Lab"
                options={listLabs("activeLabs")}
              />
              <LabsDropdown
                initiallyOpen={false}
                lab="All Labs"
                options={listLabs("allLabs")}
              />
            </>
          )}
          {showVoucherAccessTab && (
            <div className="sidebar-item">
              <div className="sidebar-item-row">
                <img
                  className="sidebar-item-icon"
                  height="22"
                  width="22"
                  src={voucherIcon}
                  alt="vouchers"
                />
                <NavLink
                  to={VOUCHERS_ROUTE}
                  onClick={toggleSidebar}
                  className="sidebar-item-name"
                >
                  Vouchers
                </NavLink>
              </div>
            </div>
          )}
          {!parsedToken.admin_access && !!storylines?.length && (
            <div className="sidebar-item">
              <div className="sidebar-item-row">
                <img
                  className="sidebar-item-icon"
                  height="22"
                  width="22"
                  src={voucherIcon}
                  alt="guides"
                />
                <NavLink
                  to={USER_STORYLINE_ROUTE}
                  onClick={toggleSidebar}
                  className="sidebar-item-name"
                >
                  Guides
                </NavLink>
              </div>
            </div>
          )}
          <div className="sidebar-item">
            <div className="sidebar-item-row">
              <img
                className="sidebar-item-icon"
                height="22"
                width="22"
                src={submitTicket}
                alt="submit ticket"
              />
              <NavLink
                to={SUBMIT_TICKET_ROUTE}
                onClick={toggleSidebar}
                className="sidebar-item-name"
              >
                Submit Ticket
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
